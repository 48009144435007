<template>
  <div class="time-range-picker">
    <div class="time-list"
         v-for="(item, index) in timeRange"
         :key="item.text">
      <div>
        <p>
          {{ index % 2 ? "" : item.text }}
        </p>
        <div :class="{radioactive: item.checked, radiobtn: !item.checked, ban: item.disabled}"
             @click="dateTimeChange(index)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import Vue from 'vue'
import dayjs from 'dayjs'
export default {
  name: 'timeRangePicker',
  data () {
    return {
      timeRange: []
    }
  },
  props: {
    vehicetime: {
      type: Array,
      request: true
    },
    changetime: {
      type: String
    },
    created: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    vehicetime: {
      handler (newValue) {
        console.log('newVal', newValue);

        if (newValue.length > 0) {
          this.timeRange.forEach(item => {
            item['checked'] = false
            item['disabled'] = false
          })
          this.timeRange = [...this.timeRange]
          newValue.forEach(el => {
            this.checkedTime(el.appointmentTime, this.timeRange)
          })
        } else {
          this.timeRange.forEach(item => {
            item['checked'] = false
            item['disabled'] = false
          })
          this.timeRange = [...this.timeRange]

          this.checkedTime('desable', this.timeRange)
        }
      },
      deep: false
    },
    // changetime: {
    //   handler (newValue) {
    //     if (newValue.length > 0) {
    //       this.checkedTimeCheck(newValue)
    //     }
    //   },
    //   deep: false
    // }
  },
  created () {
    this.timeRange = this.arriveFormat()
  },
  methods: {
    // 将当前试驾单中选择的时间显示出来
    checkedTimeCheck (timgRang) {
      const nowDate = dayjs().format('YYYY/MM/DD')
      const str = timgRang.split('-')
      const time1 = new Date(nowDate + ' ' + str[0]).getTime() // 开始时间 9:00
      const time2 = new Date(nowDate + ' ' + str[1]).getTime() // 结束时间 11:00
      this.timeRange.forEach((item) => {
        const str2 = item.code.split('-') // 要判断的
        const time3 = new Date(nowDate + ' ' + str2[0]).getTime() // 10:00
        const time4 = new Date(nowDate + ' ' + str2[1]).getTime() // 10:30
        if (time1 < time4 && time1 <= time3 && time2 >= time4) {
          item.checked = true
          item.disabled = false
        }
      })
      this.timeRange = [...this.timeRange]

    },

    // 选择预约时间的时间，时间选择必须连续并且最大不超过3个小时，每个格子代表半个小时
    dateTimeChange (index) {
      const data = this.timeRange
      const item = data[index]
      // 当前时间不可选
      if (item.disabled) {
        Toast('当前时间不可选')
        return
      }
      item['checked'] = !item['checked']
      item['desable'] = false
      // this.timeRange = data
      Vue.set(this.timeRange, index, data[index])
      const list = data.filter((el) => el['checked'])
      if (list.length > 6) {
        item.checked = false
        list.pop()
        Toast('试驾时间最长3个小时！')
      } else {
        if (list.length >= 2) {
          for (let i = 0; i <= list.length - 2; i++) {
            if (list[i + 1].index - list[i].index >= 2) {
              if (item['checked']) {
                item['checked'] = false
              } else {
                item['checked'] = true
              }
              list.pop()
              Toast('请选择连续的时间段！')
              return
            }
          }
        }
      }
      if (list.length > 0) {
        const time = list[0].code.split('-')[0] +
          '-' +
          list[list.length - 1].code.split('-')[1]
        this.$emit('timeCheck', time)
      } else {
        this.$emit('timeCheck', '')
      }
    },
    // 校验当前时间段是否可选择
    /**
     * @params {string} timgRang 传入的时间范围 9:00-9:30
     * @params {array} dataTimeList 当前时间范围 checked 是否可被选择
     */
    checkedTime (timgRang, dataTimeList) {
      this.$emit('carChange')
      if (timgRang === 'desable') {
        dataTimeList.forEach((item) => {
          item.disabled = false
        })
        this.timeRange = [...dataTimeList]
        this.$emit('carChange')
      } else {
        const nowDate = dayjs().format('YYYY/MM/DD')
        const str = timgRang.split('-')
        const time1 = new Date(nowDate + ' ' + str[0]).getTime() // 开始时间 9:00
        const time2 = new Date(nowDate + ' ' + str[1]).getTime() // 结束时间 11:00
        dataTimeList.forEach((item) => {
          const str2 = item.code.split('-') // 要判断的
          const time3 = new Date(nowDate + ' ' + str2[0]).getTime() // 10:00
          const time4 = new Date(nowDate + ' ' + str2[1]).getTime() // 10:30
          if (time1 < time4 && time1 <= time3 && time2 >= time4) {
            item.disabled = true
          }
        })
        this.timeRange = [...dataTimeList]
        if (!this.created) {
          console.log('============');

          this.checkedTimeCheck(this.changetime)
        }
      }
    },
    // 生成9-21点的数组
    arriveFormat () {
      const data = []
      let n = 9
      for (let i = 0; i < 13 * 2; i++) {
        if (i % 2 === 0) {
          data.push({
            index: i,
            code: n + i + ':00' + '-' + (n + i) + ':30',
            text: n + i + ':00'
          })
        } else {
          data.push({
            index: i,
            code: n + (i - 1) + ':30' + '-' + (n + i) + ':00',
            text: n + (i - 1) + ':30'
          })
          n = n - 1
        }
      }
      return data
    }
  }

}
</script>
<style lang="less" scoped>
.time-range-picker {
  display: flex;
  flex-wrap: wrap;
  .time-list {
    margin-top: 0.4rem;
    width: 1.1rem;
    height: 1rem;

    > div {
      position: relative;
      width: 1.1rem;
      height: 1rem;

      p {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        line-height: 0.6rem;
        height: 0.6rem;
        background: #eee;
        color: #666;
      }

      > div {
        height: 1rem;
        background: #e2eaff;
      }
      .radioactive {
        background: #DA3738;
      }

      .radiobtn {
        background: #4bb3f4;
      }

      .ban {
        background: #ccc;
      }
    }
  }

  .time-list:nth-child(odd) {
    > div {
      div {
        margin-right: 1px;
      }
    }
  }

  .time-list:nth-child(even) {
    margin-right: 4px;

    > div {
      div {
        margin-left: 1px;
      }
    }
  }
}
</style>