<template>
  <App
    :title="title"
    left-arrow
  >
    <!--  left-text="取消"
       right-text="完成"
       @click-left="onClickLeft"
       @click-right="onClickRight" -->
    <div class="content full-container">
      <DynamicForm
        v-model="model"
        :rules="rules"
        :fieldData="fieldData"
        ref="form"
      >
        <template slot="ggg-input">
          <TimeRangePicker
            ref="timerange"
            :created="created"
            @carChange="carChange"
            v-model="model.arrive"
            @timeCheck='timeCheck'
            :changetime='changetime'
            :vehicetime='vehicetime'
          />
        </template>
        <template slot="tel-input">
          <div class="testdrive-tel">
            <Field
              v-model="model.tel"
              input-align="right"
              label='选择客户'
              required
              type='tel'
              placeholder="Please fill in the phone number"
              error-message='Please fill in the phone number'
              maxlength='11'
              :disabled='telDeatadled'
            >

            </Field>
            <Popup
              v-model="telPropShow"
              position="bottom"
            >
              <Picker
                :columns="telList"
                show-toolbar
                @confirm="telOnConfirm"
              ></Picker>
            </Popup>
          </div>
        </template>
        <!-- 选择陪同人员模块 -->
        <template slot="pilot-input">
          <div>
            <Field
              v-model="pilot"
              input-align="right"
              :required='true'
            >

            </Field>
          </div>
        </template>
      </DynamicForm>
      <FooterBtn
        cancelText="返回"
        okText="提交"
        @cancel="goBack"
        @ok="testdriveCreate"
      ></FooterBtn>
    </div>
  </App>
</template>
<script>
import Vue from 'vue'
import { Field, Popup, Picker, Toast } from 'vant';
import FooterBtn from '_c/footerBtn/index'
import DynamicForm from '_c/dynamicForm'
import TimeRangePicker from './timeRangePicker.vue'

import { vehicleList, activetyList, poiltList, userList, checkTime, createTestdrive, changeTestdriveDetail, testdriveDetail } from '@/api/testdrive/index'
import dayjs from 'dayjs';
export default {
  name: 'testDriveCreate',
  components: {
    DynamicForm,
    TimeRangePicker,
    Field,
    Popup,
    Picker,
    FooterBtn
  },
  data () {
    return {
      title: '创建试驾排程',
      store: '',
      storeCode: '',// 修改排程时的门店code
      telVel: '', // 输入手机号的输入框的双向绑定对象
      telPropShow: false, // 输入手机号时下方弹框的显示与否.
      telList: [],// 输入手机号之后的下方弹框的内容绑定对象
      pilot: '默认归属人',
      model: {
        authid: '',
        name: '',
        tel: '',
        scour: '',
        store: '',
        type: '',
        typename: '',
        pilot: '',
        daterange: new Date(),
        vehice: '',
        appoint: '',
        arrive: ''
      },
      rules: {
        tel: [{ required: true, message: '请填写客户手机号', trigger: 'blur' }],
        scour: [{ required: true, message: '请选择试驾来源', trigger: 'blur' }],
        store: [{ required: true, message: '请选择预约门店', trigger: 'blur' }],
        type: [{ required: true, message: '请选择试驾类型', trigger: 'blur' }],
        typename: [{ required: true, message: 'Please select a campaign.名称', trigger: 'blur' }],
        pilot: [{ required: true, message: '请选择Co-Pilot', trigger: 'blur' }],
        appoint: [{ required: true, message: '请选择陪同人员', trigger: 'blur' }],
        daterange: [{ required: true, message: '请选择预约日期', trigger: 'blur' }],
        vehice: [{ required: true, message: '请选择试驾车辆', trigger: 'blur' }],
        arrive: [{ required: true, message: '请选择预约时间', trigger: 'blur' }]
      },
      fieldData: [
        {
          data: [
            { label: '选择客户', key: 'tel', type: 'text', props: { slot: 'tel-input' } },
            { label: '试驾来源', key: 'scour', type: 'select', options: [], props: { placeholder: '请选择试驾来源' } },
            { label: '预约门店', key: 'store', type: 'select', options: [], props: { disabled: false, placeholder: '请选择预约门店' } },
            { label: '试驾类型', key: 'type', type: 'select', options: [], props: { placeholder: '请选择试驾类型' } },
            { label: '活动类型', key: 'typename', type: 'select', visible: false, options: [], props: { placeholder: 'Please select a campaign.类型' } },
            { label: 'Co-Pilot', key: 'pilot', type: 'select', options: [{ 'label': '店长分配', 'value': 'appoint' }, { 'label': '指定人员', 'value': 'unappoint' }], props: { slot: 'pilot-input', placeholder: '请选择陪同人员' } },
            { label: '陪同人员', key: 'appoint', type: 'select', visible: false, options: [], props: { placeholder: '请选择Co-Pilot' } },
            { label: '预约日期', key: 'daterange', type: 'calendar', props: { placeholder: '请选择预约时间' } },
            { label: '试驾车辆', key: 'vehice', type: 'select', options: [], props: { placeholder: '请选择预约车辆' } },
            { label: '预约时间', key: 'arrive', direction: 'column', props: { slot: 'ggg-input' } }
          ]
        }
      ],
      vehicetime: [],// 接口返回的当前车辆的预约时间情况
      testdriveId: '',//修改时候传递过来的排程id
      created: true,// 创建还是修改
      telDeatadled: false, // 填写手机号输入框是否禁用
      Detail: {}, // 试驾单详情
      changetime: '',
      vehiceList: [],
      handlerTel: false // 是否需要监听手机号的输入

    }
  },
  watch: {
    'model.store': {
      handler () {
        //如果选择的门店不是当前登陆的门店则显示选择陪同人员否则就不显示
        if (this.model.store === this.store) {
          this.model.pilot = 'unappoint'
          Vue.set(this.fieldData[0]['data'][5], 'options', [{ 'label': '指定人员', 'value': 'unappoint' }])
          Vue.set(this.fieldData[0]['data'][6], 'visible', true)
        } else {
          this.model.pilot = 'appoint'
          Vue.set(this.fieldData[0]['data'][5], 'options', [{ 'label': '店长分配', 'value': 'appoint' }, { 'label': '指定人员', 'value': 'unappoint' }])
          Vue.set(this.fieldData[0]['data'][6], 'visible', false)
        }
        //选择完门店之后获取试驾车辆列表
        if (this.model.store !== '') {
          this.getVehiceList()
        }
      }
    },
    'model.pilot': {
      handler () {
        //如果选择了店长分配就不展示陪同人员列表，否则展示
        if (this.model.pilot === 'appoint') {
          Vue.set(this.fieldData[0]['data'][6], 'visible', false)
        } else {
          Vue.set(this.fieldData[0]['data'][6], 'visible', true)
          this.getPoiltList()
        }
      }
    },
    'model.type': {
      handler () {
        //判断当前选择的试驾类型是否是活动试驾，如果是就展示活动
        if (this.model.type === "ACTIVITY") {
          Vue.set(this.fieldData[0]['data'][4], 'visible', true)
          this.getActivetyList()
          const valueList = this.vehiceList.filter(el => {
            return el.vehicleStatus === 'MARKETING_ACTIVITY'
          })
          Vue.set(this.fieldData[0]['data'][8], 'options', [...valueList])
        } else {
          Vue.set(this.fieldData[0]['data'][4], 'visible', false)
          const valueList = this.vehiceList.filter(el => {
            return el.vehicleStatus === 'APPOINTMENT_ABLE'
          })
          Vue.set(this.fieldData[0]['data'][8], 'options', [...valueList])
        }
      },
      deep: true
    },
    'model.vehice': {
      handler () {
        //根据所选的门店，日期，车辆获取当前车辆的预约情况
        if (this.model.vehice !== '' && this.model.store !== '' && this.model.daterange !== '') {
          this.getCheckTime()
        }
      }
    },
    'model.daterange': {
      handler () {
        this.todayTimeChange()
        if (this.model.store !== '' && this.model.vehice !== '') {
          this.getCheckTime()
        }
      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    // 获取页 面参数
    async getQuery () {
      // 如果有id就说明是修改试驾
      if (this.$route.query && this.$route.query.id) {
        await this.getTestdriveDetail(this.$route.query.id)
        await this.getVehiceList(this.Detail.storeCode)
        this.testdriveId = this.Detail.testDriveId
        this.telVel = this.Detail.accountPhone
        this.model.store = this.Detail.storeName
        this.storeCode = this.Detail.storeCode
        this.model.tel = this.Detail.accountPhone
        this.model.scour = this.Detail.testDriveSource
        this.model.type = this.Detail.testDriveType
        if (this.Detail.testDriveType === 'ACTIVITY') {
          this.model.typename = this.Detail.campaignSourceCode
          Vue.set(this.fieldData[0].data[4], 'visible', true)
        }
        this.model.appoint = this.Detail.coPilotId
        this.model.daterange = new Date(this.Detail.appointmentDate)
        this.model.arrive = this.Detail.appointmentTime
        this.model.vehice = this.Detail.vehicleId
        this.changetime = this.Detail.appointmentTime
        this.created = false
        this.telDeatadled = true
        this.title = '修改试驾排程'
        Vue.set(this.fieldData[0].data[2], 'type', 'text')
        Vue.set(this.fieldData[0].data[2].props, 'disabled', true)
        this.handlerTel = false
        // this.getPoiltList()
        // this.getVehiceList()
      } else {
        this.title = '创建试驾排程'
        this.created = true
        // 如果有手机号就说明是从客户详情进来的
        if (this.$route.query && this.$route.query.phone) {
          this.telVel = this.$route.query.phone
          this.getUserList(this.telVel)
          this.telDeatadled = true
          this.handlerTel = false
        } else {
          // 从试乘试驾页面进来的需要监听手机号的输入事件
          this.handlerTel = true
        }

      }
    },
    // 获取试驾详情
    async getTestdriveDetail (id) {
      const res = await testdriveDetail(id)
      if (res.success) {
        this.Detail = res.data
      } else {
        Toast(res.msg)
      }
    },
    // 提交按钮事件
    testdriveCreate () {
      this.$refs.form.validate().then(res => {
        if (res) {

          if (this.created) {
            this.establishTestDrive()
          } else {
            this.changeTestdrive()
          }
        }
      })
    },
    // 修改试驾排程事件
    async changeTestdrive () {
      // 判断当前试驾的时间是否是今天逾期掉的时间段
      if (new Date(this.model.daterange).toDateString() !== new Date().toDateString() && new Date(this.model.daterange).getTime() < new Date().getTime()) {
        Toast('请修改预约日期')
        return
      } else {
        if (new Date(this.model.daterange).toDateString() === new Date().toDateString()) {
          const hours = this.model.arrive.split('-')[0].split(':')[0]
          const minutes = this.model.arrive.split('-')[0].split(':')[1]
          if (hours < new Date().getHours()) {
            Toast('请修改预约时间')
            return
          } else {
            if (hours == new Date().getHours() && minutes + 30 < new Date().getMinutes()) {
              Toast('请修改预约时间')
              return
            }
          }
        }
      }
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const dataTime = dayjs(this.model.daterange).format('YYYY-MM-DD')
      const params = this.model.appoint === '' ? {
        "testDriveSource": this.model.scour,//试驾来源,
        "testDriveType": this.model.type,// 试驾类型,
        "campaignSourceCode": this.model.typename,//选择活动试驾时的活动名称,
        "appointmentDate": dataTime,
        "appointmentTime": this.model.arrive,//选择的具体时间'9:30-10:00'
        "eosTestDriveVehicleId": this.model.vehice// 选择的试驾车辆
      } : {
        "testDriveSource": this.model.scour,//试驾来源,
        "testDriveType": this.model.type,// 试驾类型,
        "campaignSourceCode": this.model.typename,//选择活动试驾时的活动名称,
        "coPilot": this.model.appoint,//陪同人员，选择店长在指定的时候没有陪同人员传空或者不传,
        "appointmentDate": dataTime,
        "appointmentTime": this.model.arrive,//选择的具体时间'9:30-10:00'
        "eosTestDriveVehicleId": this.model.vehice// 选择的试驾车辆
      }
      const res = await changeTestdriveDetail(this.testdriveId, params)
      Toast.clear()
      if (res.success) {
        Toast('修改成功')
        this.$router.go(-1)
      } else {
        Toast(res.msg)
      }
    },
    //手机号弹框的点击确定事件
    telOnConfirm (item) {
      this.model.authid = item.eosAccountId
      this.model.name = item.name
      this.model.tel = item.phone
      this.telVel = item.phone
      this.telPropShow = false;
    },
    // 从本地存储中获取试驾来源
    getScour () {
      const baseDate = this.$storage.get('baseData')
      const scourList = []
      baseDate['testdrive_source'].forEach(el => {
        scourList.push({
          'label': el.key,
          'value': el.value
        })
      })
      Vue.set(this.fieldData[0]['data'][1], 'options', scourList)
    },
    // 获取全部全部门店列表
    async getStoreList () {
      const data = this.$storage.get('saleDlr')
      data.forEach(el => {
        el['label'] = el.name
        el['value'] = el.code
      })
      Vue.set(this.fieldData[0]['data'][2], 'options', data)
    },
    // 获取当前角色所属的门店列表
    getUserStoreList () {
      const data = this.$storage.get('userStore')
      this.store = data[0].code
      this.model.store = data[0].code
    },
    // 根据手机号搜索用户
    async getUserList (tel) {
      let user
      const res = await userList({
        phone: tel//用户输入的手机号
      })
      if (res.data.length > 0) {
        this.model.authid = res.data[0].eosAccountId
        this.model.name = res.data[0].qbsAccountName
        this.model.tel = res.data[0].qbsAccountPhone
        this.telVel = res.data[0].qbsAccountPhone
        user = true
      } else {
        Toast('角色变更，无匹配数据')
        user = false
      }
      return user
    },
    // 获取试驾类型 从本地存储中获取
    getTestdriveType () {
      const basedata = this.$storage.get('baseData')
      const driveTypeList = []
      basedata['testdrive_type'].forEach(el => {
        driveTypeList.push({
          'label': el.key,
          'value': el.value
        })
      })
      Vue.set(this.fieldData[0]['data'][3], 'options', driveTypeList)
    },
    // 获取试驾活动列表
    async getActivetyList () {
      const { data } = await activetyList()
      const list = []
      data.records.forEach(el => {
        list.push({
          'label': el.name,
          'value': el.code
        })
      })
      Vue.set(this.fieldData[0]['data'][4], 'options', list)
    },
    // 获取陪同人员列表
    async getPoiltList () {
      try {
        const { data } = await poiltList({
          storeCode: this.created ? this.model.store : this.storeCode// 门店id
        })
        const list = []
        if (data.length > 0) {
          data.forEach(el => {
            list.push({
              'label': el.name,
              'value': el.idmUserid
            })
          })
        } else {
          Toast('该门店没有陪同人员')
        }
        Vue.set(this.fieldData[0]['data'][6], 'options', list)
      } catch (error) {
        Toast('请先选择门店')
      }
    },

    // 获取试驾车辆列表
    async getVehiceList () {
      const res = await vehicleList({
        storeCode: this.created ? this.model.store : this.storeCode, // 门店编码
        page: 1,
        size: 999
      });
      if (res.success) {
        this.vehiceList = res.data['records'].map(el => {
          return {
            'label': el['vehicleName'],
            'value': el['eosTestDriveVehicleId'],
            ...el
          }
        })
      }
    },
    //根据选择的门店，时间，车俩获取当前车辆的可选时间
    async getCheckTime () {
      const time = dayjs(this.model.daterange).format('YYYY-MM-DD')
      const res = await checkTime({
        storeCode: this.created ? this.model.store : this.storeCode,// 门店编码
        startDate: time,// 开始时间格式YYYY-MM-DD
        endDate: time,// 结束时间格式YYYY-MM-DD
        vehicleId: this.model.vehice//当前选择车辆的id
      })
      if (res) {
        this.vehicetime = res.data
      }
      // this.todayTimeChange()
    },
    // 子组件传递过来的时间选择
    timeCheck (e) {
      this.model.arrive = e
    },
    // 创建试驾排程
    establishTestDrive () {

      this.getUserList(this.model.tel).then(async res => {
        if (res) {
          /* Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay: true
          }) */
          const dataTime = dayjs(this.model.daterange).format('YYYY-MM-DD')
          const createParams = {
            eosAccountId: this.model.authid,//客户id
            qbsAccountPhone: this.model.tel,//客户手机号
            qbsAccountName: this.model.name,//客户姓名
            testDriveSource: this.model.scour,//试驾来源
            eosStoreCode: this.model.store,//预约的门店
            testDriveType: this.model.type,// 试驾类型
            campaignSourceCode: this.model.typename,//选择活动试驾时的活动名称
            appointmentDate: dataTime,//预约日期格式YYYY-MM-DD
            appointmentTime: this.model.arrive,//选择的具体时间'9:30-10:00'
            eosTestDriveVehicleId: this.model.vehice// 选择的试驾车辆
          }
          // 如果选择了陪同人员就传如果没有就不传该参数，该参数传空值的话虽说可以创建成功但是获取详情会500
          if (this.model.appoint) {
            createParams['coPilot'] = this.model.appoint //陪同人员
          }
          const { data, msg } = await createTestdrive(createParams)
          Toast.clear()
          if (data) {
            Toast('创建成功')
            this.$router.go(-1)
          } else {
            Toast.clear()
            Toast(`${msg}`)
          }
        }

      })
    },
    // 预约车辆改变后重新判断时间段是否可选
    carChange () {
      this.todayTimeChange()
    },
    // 判断当前所选日期的时间有哪些不是逾期的
    todayTimeChange () {
      // 判断当前选择的时间是否是今天
      // eslint-disable-next-line eqeqeq
      if (new Date(this.model['daterange']).toDateString() == new Date().toDateString()) {
        const YY = new Date().getFullYear()
        const MM = new Date().getMonth() + 1
        const DD = new Date().getDate()
        // eslint-disable-next-line no-unused-vars
        let desableIndex
        this.$refs.timerange.timeRange.forEach((el, index) => {
          if (new Date(`${YY}/${MM}/${DD} ${el.code.split('-')[1]}`) <= new Date().getTime()) {
            desableIndex = index
          }
        })
        this.$refs.timerange.timeRange.forEach((eltime, index) => {
          if (index <= desableIndex) {
            eltime.disabled = true
            // this.$Message.error('当前时间不可选')
          }
        })
      } else {
        this.$refs.timerange.timeRange.forEach((eltime) => {
          eltime.disabled = false
        })
      }
      this.$refs.timerange.timeRange = [...this.$refs.timerange.timeRange]
    },
    init () {
      this.getQuery()
      // this.getUserList('')
      this.getTestdriveType()
      this.getStoreList()
      this.getScour()
      this.getUserStoreList()
      this.todayTimeChange()

    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
/deep/.van-field__label {
  padding-left: 0.2rem;
  position: relative;
  span::before {
    position: absolute;
    left: 0;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
  }
}

.content {
  padding-bottom: 20px;
  background-color: #fff;
  .testdrive-tel {
    padding-left: 0.32rem;
  }
  .footer-btn {
    position: unset;
    margin-bottom: 30px;
  }
}
</style>